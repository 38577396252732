import React from 'react';
import { InputCheckbox, InputDateTime, InputText, InputToggleSwitch } from '../../../components';
import { CreateAlert, Icon, InputWraper, onError, useForm } from '../../../modules';
import { ConfigService } from '../../../services/config/config.service';
import { store, useSelector } from '../../../store';
import { withConfigWraper } from '../wraper';
import * as Yup from "yup";
import { translate } from '../../../languages';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { EUserRole } from '../../../types';
import { Routes } from '../../../AppRoutes';

export const MaintenanceConfig = withConfigWraper(() => {

    const user = useSelector((state) => state.user);
    const history = useHistory();
    if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
        history.replace(Routes.dashboard.path);
        return null;
    }
    
    const maintenanceConfig = useSelector((state) => state.maintenanceConfig);
    const maintenanceJobServiceConfig = useSelector((state) => state.maintenanceJobServiceConfig);

    const maintenanceScanDepositServiceConfig = useSelector((state) => state.maintenanceScanDepositServiceConfig);
    const maintenanceWithdrawServiceConfig = useSelector((state) => state.maintenanceWithdrawServiceConfig);
    const maintenanceMecServiceConfig = useSelector((state) => state.maintenanceMecServiceConfig);
    const maintenanceCloseOrderServiceConfig = useSelector((state) => state.maintenanceCloseOrderServiceConfig);

    // const checkConfig = () => {
    //     if (maintenanceConfig) {
    //         const body = {
    //             enable: false
    //         };
    //         return ConfigService.swithMaintenanceServer(body)
    //             .then(async () => {
    //                 CreateAlert({
    //                     type: 'success',
    //                     message: 'Set config maintenance successful.'
    //                 });
    //                 await ConfigService.getServerConfigMaintenance(store);
    //             })
    //             .catch(onError);
    //     } else {
    //         const body = {
    //             enable: true
    //         };
    //         return ConfigService.swithMaintenanceServer(body)
    //             .then(async () => {
    //                 CreateAlert({
    //                     type: 'success',
    //                     message: 'Set config maintenance successful.'
    //                 });
    //                 await ConfigService.getServerConfigMaintenance(store);
    //             })
    //             .catch(onError);
    //     }
    // };

    const { handleSubmit, getInputProps, isSubmitting, isValid } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: 'maintenanceTitle',
                defaultValue: maintenanceConfig?.text ?? null,
                validate: Yup.string()
                    .required(translate('must-be-provided')),
                    // .integer(translate('Number must be integer'))
                isDisabled: maintenanceConfig.enable
            },
            {
                name: 'maintenanceTime',
                defaultValue: maintenanceConfig?.time ?? null,
                validate: Yup.string()
                    .required(translate('must-be-provided')),
                isDisabled: maintenanceConfig.enable
            }
        ],
        onSubmit: async (values) => {
            let payload = {
                enable: !maintenanceConfig.enable,
                text: values?.maintenanceTitle,
                time: moment(values?.maintenanceTime).toISOString()
            };
            return ConfigService.handleMaintenance(payload)
                .then(async () => {
                    await ConfigService.getServerConfigMaintenance(store);
                    CreateAlert({
                        message: translate('Change Maintenance successfully'),
                        type: 'success'
                    });
                })
                .catch(onError);
        }
    });

    const checkConfigMaintenanceJobService = () => {
        return ConfigService.swithMaintenanceJobService(!maintenanceJobServiceConfig)
        .then(async () => {
          CreateAlert({ type: "success", message: "Set config maintenance successful." });
          await ConfigService.getJobServiceConfigMaintenance(store);
        })
        .catch(onError);
      };

    const checkConfigMaintenanceScanDepositService = () => {
        return ConfigService.swithMaintenanceScanDepositService(!maintenanceScanDepositServiceConfig)
        .then(async () => {
            CreateAlert({ type: "success", message: "Set config maintenance successful." });
            await ConfigService.getScanDepositServiceConfigMaintenance(store);
        })
        .catch(onError);
    };

    const checkConfigMaintenanceWithdrawService = () => {
        return ConfigService.swithMaintenanceWithdrawService(!maintenanceWithdrawServiceConfig)
        .then(async () => {
            CreateAlert({ type: "success", message: "Set config maintenance successful." });
            await ConfigService.getWithdrawServiceConfigMaintenance(store);
        })
        .catch(onError);
    };

    const checkConfigMaintenanceMecService = () => {
        return ConfigService.swithMaintenanceMecService(!maintenanceMecServiceConfig)
        .then(async () => {
            CreateAlert({ type: "success", message: "Set config maintenance successful." });
            await ConfigService.getMecServiceConfigMaintenance(store);
        })
        .catch(onError);
    };

    const checkConfigMaintenanceCloseOrderService = () => {
        return ConfigService.swithMaintenanceCloseOrderService(!maintenanceCloseOrderServiceConfig)
        .then(async () => {
            CreateAlert({ type: "success", message: "Set config maintenance successful." });
            await ConfigService.getCloseOrderServiceConfigMaintenance(store);
        })
        .catch(onError);
    };

    return (
        <div className="container-fluid maintenance-config">
            <div className="row">
                <div className="col-3">
                    <div className="row">
                        <div className="col-12">
                            <InputWraper
                                enableReinitialize
                                label={translate("Title")}
                                inputProps={getInputProps("maintenanceTitle")}
                                component={InputText}
                            />
                        </div>
                        <div className="col-12">
                            <InputWraper
                                enableReinitialize
                                label={translate("Maintenance Time To")}
                                inputProps={getInputProps("maintenanceTime")}
                                component={InputDateTime}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {isSubmitting === true ? (
                                <div className="handle-loading">
                                    <Icon.Loading />
                                </div>
                            ) : (
                                <InputToggleSwitch
                                onChange={(value, e) => {
                                    if (isValid === false) e?.preventDefault();
                                    if (isSubmitting) return;
                                    handleSubmit();
                                }}
                                value={maintenanceConfig?.enable}
                                onTouched={() => false}
                                label="ON/OFF"
                                name=""
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: "20px", borderTop: "1px solid #8f9bb3" }}>
                <div className="col-12"  style={{ marginTop: "20px" }}>
                    {isSubmitting === true ? (
                        <div className="handle-loading">
                            <Icon.Loading />
                        </div>
                    ) : (
                        // <InputToggleSwitch
                        // onChange={(value, e) => {
                        //     if (isValid === false) e?.preventDefault();
                        //     if (isSubmitting) return;
                        //     checkConfigMaintenanceJobService();
                        // }}
                        // value={maintenanceJobServiceConfig}
                        // onTouched={() => checkConfigMaintenanceJobService()}
                        // label="Maintenance Job Service"
                        // name=""
                        // />
                        <InputCheckbox
                        type={"checkbox"}
                        label="Maintenance Job Service"
                        value={maintenanceJobServiceConfig}
                        onChange={() => checkConfigMaintenanceJobService()}
                        name=""
                        onTouched={() => checkConfigMaintenanceJobService()}
                      />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12"  style={{ marginTop: "10px" }}>
                    {isSubmitting === true ? (
                        <div className="handle-loading">
                            <Icon.Loading />
                        </div>
                    ) : (
                        <InputCheckbox
                        type={"checkbox"}
                        label="Maintenance Scan Deposit Service"
                        value={maintenanceScanDepositServiceConfig}
                        onChange={() => checkConfigMaintenanceScanDepositService()}
                        name=""
                        onTouched={() => checkConfigMaintenanceScanDepositService()}
                      />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12"  style={{ marginTop: "10px" }}>
                    {isSubmitting === true ? (
                        <div className="handle-loading">
                            <Icon.Loading />
                        </div>
                    ) : (
                        <InputCheckbox
                        type={"checkbox"}
                        label="Maintenance Withdraw Service"
                        value={maintenanceWithdrawServiceConfig}
                        onChange={() => checkConfigMaintenanceWithdrawService()}
                        name=""
                        onTouched={() => checkConfigMaintenanceWithdrawService()}
                      />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12"  style={{ marginTop: "10px" }}>
                    {isSubmitting === true ? (
                        <div className="handle-loading">
                            <Icon.Loading />
                        </div>
                    ) : (
                        <InputCheckbox
                        type={"checkbox"}
                        label="Maintenance Mec Service"
                        value={maintenanceMecServiceConfig}
                        onChange={() => checkConfigMaintenanceMecService()}
                        name=""
                        onTouched={() => checkConfigMaintenanceMecService()}
                      />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12"  style={{ marginTop: "10px" }}>
                    {isSubmitting === true ? (
                        <div className="handle-loading">
                            <Icon.Loading />
                        </div>
                    ) : (
                        <InputCheckbox
                        type={"checkbox"}
                        label="Maintenance Close Order Service"
                        value={maintenanceCloseOrderServiceConfig}
                        onChange={() => checkConfigMaintenanceCloseOrderService()}
                        name=""
                        onTouched={() => checkConfigMaintenanceCloseOrderService()}
                      />
                    )}
                </div>
            </div>
        </div>
    );
});
