import { Store } from "redux";
import { store } from "../../store";
import { CookieService, ECookieVariable } from "../cookie";
import { RequestMainService } from "../request";
import { GET_CANDLE_CONTROL_CONFIG, GET_CANDLE_CONTROL_CONFIG_ERROR } from "./candleControl.reduce";
import { GET_LEVERAGES_CONFIG, GET_LEVERAGES_CONFIG_ERROR } from "./leveragesConfig.reduce";
import { GET_MAINTENANCE_CONFIG, GET_MAINTENANCE_CONFIG_ERROR } from "./maintenance.reduce";
import { GET_ROLE_PERMISSION_CONFIG, GET_ROLE_PERMISSION_CONFIG_ERROR } from "./rolePermission";
import { GET_STOP_LOSS_TAKE_PROFIT_CONFIG, GET_STOP_LOSS_TAKE_PROFIT_CONFIG_ERROR } from "./stopLossTakeProfit.reduce";
import { GET_WITHDRAW_CONFIG, GET_WITHDRAW_CONFIG_ERROR } from "./withdraw.reduce";
import { GET_MAINTENANCE_JOB_SERVICE, GET_MAINTENANCE_JOB_SERVICE_ERROR } from "./maintenanceJobService.reduce";
import { GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE, GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE_ERROR } from "./maintenanceScanDepositService.reduce";
import { GET_MAINTENANCE_WITHDRAW_SERVICE, GET_MAINTENANCE_WITHDRAW_SERVICE_ERROR } from "./maintenanceWithdrawService.reduce";
import { GET_MAINTENANCE_MEC_SERVICE, GET_MAINTENANCE_MEC_SERVICE_ERROR } from "./maintenanceMecService.reduce";
import { GET_MAINTENANCE_CLOSE_ORDER_SERVICE, GET_MAINTENANCE_CLOSE_ORDER_SERVICE_ERROR } from "./maintenanceCloseOrderService.reduce";
export class ConfigService {
  static async getServerConfig(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/get-server-config`)
        .then(({ result }) => {
          const data = result.find((v: any) => v.serverConfigName === "WITHDRAW_LIMIT_CONFIG");
          const { USER_MAXIMUM_IN_DAY, SYSTEM_MAXIMUM_IN_DAY, BEP20_FEE, TRC20_FEE, WITHDRAW_PROCESSING_TIME } = JSON.parse(data.value);
          store.dispatch({
            type: GET_WITHDRAW_CONFIG,
            data: { USER_MAXIMUM_IN_DAY, SYSTEM_MAXIMUM_IN_DAY, BEP20_FEE, TRC20_FEE, WITHDRAW_PROCESSING_TIME },
          });

          const dataCandle = result.find((v: any) => v.serverConfigName === "CANDLE_CONTROL_SETTING");
          const { RANGE_RANDOM_MIN, RANGE_RANDOM_MAX } = JSON.parse(dataCandle.value);

          store.dispatch({
            type: GET_CANDLE_CONTROL_CONFIG,
            data: {
              RANGE_RANDOM_MIN,
              RANGE_RANDOM_MAX,
              serverConfigId: dataCandle.serverConfigId,
              isActiveScan: dataCandle.isActiveScan,
              shouldProcessWithdraw: dataCandle.shouldProcessWithdraw,
              reservedEth: dataCandle.reservedEth,
              withdrawRequestExpiration: dataCandle.withdrawRequestExpiration,
              serverConfigName: dataCandle.serverConfigName,
            },
          });

          const dataStopLossTakeProfit = result.find((v: any) => v.serverConfigName === "STOP_LOSS_TAKE_PROFIT_CONFIG");
          const { STOP_LOSS, TAKE_PROFIT } = JSON.parse(dataStopLossTakeProfit.value);
          store.dispatch({
            type: GET_STOP_LOSS_TAKE_PROFIT_CONFIG,
            data: {
              STOP_LOSS,
              TAKE_PROFIT,
              serverConfigId: dataStopLossTakeProfit.serverConfigId,
              isActiveScan: dataStopLossTakeProfit.isActiveScan,
              shouldProcessWithdraw: dataStopLossTakeProfit.shouldProcessWithdraw,
              reservedEth: dataStopLossTakeProfit.reservedEth,
              withdrawRequestExpiration: dataStopLossTakeProfit.withdrawRequestExpiration,
              serverConfigName: dataStopLossTakeProfit.serverConfigName,
            },
          });
        })
        .catch((res) => {
          store.dispatch({
            type: GET_WITHDRAW_CONFIG_ERROR,
            error: res.error,
          });

          store.dispatch({
            type: GET_CANDLE_CONTROL_CONFIG_ERROR,
            error: res.error,
          });

          store.dispatch({
            type: GET_STOP_LOSS_TAKE_PROFIT_CONFIG_ERROR,
            error: res.error,
          });
        });
  }

  static async setConfig(payload: any) {
    return RequestMainService.post(`/admin/server-config`, payload).then(() => {
      this.getServerConfig(store);
    });
  }

  static async getServerConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/get-maintenance-server`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_CONFIG, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_CONFIG_ERROR,
            error: res.error,
          })
        );
  }

  static async handleMaintenance(payload: any) {
    return RequestMainService.post(`/admin/switch-maintenance-server`, payload);
  }

  static async updateRolePermissionServer(id: number, status: boolean) {
    return RequestMainService.put(`/admin/role-permission/${id}/${status}`);
  }

  static async getServerConfigRolePermission(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/role-permission`)
        .then((res) => {
          store.dispatch({
            type: GET_ROLE_PERMISSION_CONFIG,
            data: res.result,
          });
        })
        .catch((err) => {
          store.dispatch({
            type: GET_ROLE_PERMISSION_CONFIG_ERROR,
            error: err.error,
          });
        });
  }

  static async getActivitiesSetting(payload: any) {
    return RequestMainService.get(`/admin/user-activity`, payload).then((res) => ({
      count: res.result.total,
      data: res.result.data,
    }));
  }

  static getListActionActivity() {
    return [
      { label: "VIEW", value: "VIEW" },
      { label: "EDIT", value: "EDIT" },
      { label: "CREATE", value: "CREATE" },
      { label: "DELETE", value: "DELETE" },
      { label: "APPROVE", value: "APPROVE" },
      { label: "REJECT", value: "REJECT" },
      { label: "LOGIN", value: "LOGIN" },
      { label: "LOGOUT", value: "LOGOUT" },
    ];
  }

  // static async getLeverages(store: Store) {
  //   return RequestMainService.get(`/admin/leverages`)
  //     .then(({ result }) => {
  //       const data = result.data.filter((item: any) => item.active)[0];

  //       store.dispatch({
  //         type: GET_LEVERAGES_CONFIG,
  //         data,
  //       });
  //     })
  //     .catch((res) =>
  //       store.dispatch({
  //         type: GET_LEVERAGES_CONFIG_ERROR,
  //         error: res.error,
  //       })
  //     );
  // }

  static async editLeverages(id: number, payload: any) {
    return RequestMainService.put(`/admin/leverages/${id}`, payload);
  }

  static async getListLeverage() {
    return RequestMainService.get("/admin/leverages").then((res) => ({
      count: res.result.length,
      data: res.result,
    }));
  }

  static async getWalletTaken() {
    return RequestMainService.get("/admin/get-wallet-taken");
  }

  static async editWalletTaken(payload: any) {
    return RequestMainService.post("/admin/set-wallet-taken", payload);
  }

  static async getListAnnouncement() {
    return RequestMainService.get("/admin/announcement").then((res) => ({
      count: res.result.total,
      data: res.result.data.sort((a: any, b: any) => a.poolStreakId - b.poolStreakId),
    }));
  }

  static async editAnnouncement(id: number, payload: any) {
    return RequestMainService.put(`/admin/announcement/${id}`, payload);
  }

  static async createAnnouncement(payload: any) {
    return RequestMainService.post("/admin/announcement", payload);
  }

  static getConfigDTE() {
    return RequestMainService.get("/admin/balance-bonus-config").then(({ result }) => result);
  }

  static updateConfigDTE(params: any) {
    return RequestMainService.put("/admin/balance-bonus-config", params);
  }

  static async getJobServiceConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/maintenance/job`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_JOB_SERVICE, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_JOB_SERVICE_ERROR,
            error: res.error,
          })
        );
  }

  static async swithMaintenanceJobService(flag: any) {
    return RequestMainService.put(`/admin/maintenance/job/${flag}`);
  }

  static async editMetConversionConfig(coinId: number, payload: any) {
    return RequestMainService.put(`/met-convert/${coinId}`, payload);
  }

  static async transferMetRequest(payload: any) {
    return RequestMainService.post(`/admin/admin-transfer/met`, payload)
  }

  static async getServerConfigByName(serverConfigName: string) {
    return RequestMainService.get(`/admin/get-server-config`).then((res) => {
      const data = res?.result?.find((v: any) => v.serverConfigName === serverConfigName);
      return JSON.parse(data.value);
    })
  }

  //#region MAINTENANCE_SCAN_DEPOSIT_SERVICE
  static async getScanDepositServiceConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/maintenance/scan-deposit`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE_ERROR,
            error: res.error,
          })
        );
  }

  static async swithMaintenanceScanDepositService(flag: any) {
    return RequestMainService.put(`/admin/maintenance/scan-deposit/${flag}`);
  }
  //#endregion

  //#region MAINTENANCE_WITHDRAW_SERVICE
  static async getWithdrawServiceConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/maintenance/withdraw`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_WITHDRAW_SERVICE, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_WITHDRAW_SERVICE_ERROR,
            error: res.error,
          })
        );
  }

  static async swithMaintenanceWithdrawService(flag: any) {
    return RequestMainService.put(`/admin/maintenance/withdraw/${flag}`);
  }
  //#endregion

  //#region MAINTENANCE_WITHDRAW_SERVICE
  static async getMecServiceConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/maintenance/mec`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_MEC_SERVICE, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_MEC_SERVICE_ERROR,
            error: res.error,
          })
        );
  }

  static async swithMaintenanceMecService(flag: any) {
    return RequestMainService.put(`/admin/maintenance/mec/${flag}`);
  }
  //#endregion

  //#region MAINTENANCE_CLOSE_ORDER_SERVICE
  static async getCloseOrderServiceConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/maintenance/close-order`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_CLOSE_ORDER_SERVICE, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_CLOSE_ORDER_SERVICE_ERROR,
            error: res.error,
          })
        );
  }

  static async swithMaintenanceCloseOrderService(flag: any) {
    return RequestMainService.put(`/admin/maintenance/close-order/${flag}`);
  }
  //#endregion


  static async getMetPoolPromotionConfig() {
    return RequestMainService.get(`/admin/met-pool-promotion-config`);
  }

  static async setMetPoolPromotionConfig(payload: any) {
    return RequestMainService.post(`/admin/met-pool-promotion-config`, payload);
  }
}
