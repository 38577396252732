const defaultState = null;

export const GET_MAINTENANCE_CLOSE_ORDER_SERVICE = "GET_MAINTENANCE_CLOSE_ORDER_SERVICE";
export const GET_MAINTENANCE_CLOSE_ORDER_SERVICE_ERROR = "GET_MAINTENANCE_CLOSE_ORDER_SERVICE_ERROR";

export const maintenanceCloseOrderServiceConfigReducer = (state = defaultState, action: any) => {
  const { type } = action;
  if (type === GET_MAINTENANCE_CLOSE_ORDER_SERVICE) return action.data;
  if (type === GET_MAINTENANCE_CLOSE_ORDER_SERVICE_ERROR) return defaultState;
  return state;
};