import { combineReducers } from "redux";

import { mainReducer } from "../services/main/main.reducer";
import { tradeHistoriesReducer } from "../services/trade/tradeHistories.reducer";
import { symbolsReducer } from "../services/trade/symbols.reducer";
import { countriesReducer } from "../services/main/contries.reducer";
import { coinConversionRateReducer } from "../services/bank/coinConversionRate.reducer";
import { tradeLastResultReducer } from "../services/trade/tradeLastResult.reducer";
import { resultPannelDropletsReducer } from "../services/trade/resultPannelDroplets.reducer";
import { coinsReducer } from "../services/trade/coins.reducer";
import { userReducer } from "../services/user/user.reducer";
import { userExternalWalletsReducer } from "../services/user/userExternalWallets.reducer";
import { userInternalWalletsReducer } from "../services/user/userInternalWallets.reducer";
import { userWalletBalancesReducer } from "../services/user/userWalletBalances.reducer";
import { openedOrdersReducer } from "../services/order/openedOrders.reducer";
import { withdrawConfigReducer } from "../services/config/withdraw.reduce";
import { maintenanceConfigReducer } from "../services/config/maintenance.reduce";
import { rolePermissionConfigReducer } from "../services/config/rolePermission";
import { candleControlConfigReducer } from "../services/config/candleControl.reduce";
import { stopLossTakeProfitConfigReducer } from "../services/config/stopLossTakeProfit.reduce";
import { leveragesConfigReducer } from "../services/config/leveragesConfig.reduce";
import { interventionReducer } from "./../services/trade/intervention.reducer";
import { slippageReducer } from "../services/trade/slippage.reducer";
import { maintenanceJobServiceConfigReducer } from "../services/config/maintenanceJobService.reduce";
import { maintenanceScanDepositServiceConfigReducer } from "../services/config/maintenanceScanDepositService.reduce";
import { maintenanceWithdrawServiceConfigReducer } from "../services/config/maintenanceWithdrawService.reduce";
import { maintenanceMecServiceConfigReducer } from "../services/config/maintenanceMecService.reduce";
import { maintenanceCloseOrderServiceConfigReducer } from "../services/config/maintenanceCloseOrderService.reduce";

export const reducers = combineReducers({
  main: mainReducer,

  user: userReducer,
  userExternalWallets: userExternalWalletsReducer,
  userInternalWallets: userInternalWalletsReducer,
  userWalletBalances: userWalletBalancesReducer,

  countries: countriesReducer,

  symbols: symbolsReducer,
  coins: coinsReducer,
  coinConversionRate: coinConversionRateReducer,

  tradeHistories: tradeHistoriesReducer,
  tradeLastResult: tradeLastResultReducer,

  resultPannelDroplets: resultPannelDropletsReducer,

  openedOrders: openedOrdersReducer,

  withdrawConfig: withdrawConfigReducer,
  maintenanceConfig: maintenanceConfigReducer,
  maintenanceJobServiceConfig: maintenanceJobServiceConfigReducer,
  rolePermissionConfig: rolePermissionConfigReducer,
  candleControlConfig: candleControlConfigReducer,
  stopLossTakeProfitConfig: stopLossTakeProfitConfigReducer,
  leveragesConfig: leveragesConfigReducer,
  intervention: interventionReducer,
  slippage: slippageReducer,

  maintenanceScanDepositServiceConfig: maintenanceScanDepositServiceConfigReducer,
  maintenanceWithdrawServiceConfig: maintenanceWithdrawServiceConfigReducer,
  maintenanceMecServiceConfig: maintenanceMecServiceConfigReducer,
  maintenanceCloseOrderServiceConfig: maintenanceCloseOrderServiceConfigReducer,
});
